import helper from "@/helper/index.js";
import Const from "@/consts/index.js";
import { FileMimeType } from "@/types/common";

/*
  @params
  byteSize: 파일용량, fixed: 소숫점 자리수
 */
export function fileSizeWithUnit(byteSize: number, fixed?: number): string {
  let str: string;
  let byteSizeString: string | number;
  //MB 단위 이상일때 MB 단위로 환산
  if (byteSize >= 1024 * 1024) {
    byteSize = byteSize / (1024 * 1024);
    byteSizeString = fixed === undefined ? byteSize : byteSize.toFixed(fixed);
    str = helper.priceFormat(byteSizeString) + " MB";
  }
  //KB 단위 이상일때 KB 단위로 환산
  else if (byteSize >= 1024) {
    byteSize = byteSize / 1024;
    byteSizeString = fixed === undefined ? byteSize : byteSize.toFixed(fixed);
    str = helper.priceFormat(byteSizeString) + " KB";
  }
  //KB 단위보다 작을때 byte 단위로 환산
  else {
    byteSizeString = fixed === undefined ? byteSize : byteSize.toFixed(fixed);
    str = helper.priceFormat(byteSizeString) + " byte";
  }
  return str;
}

export function isVideoTypeFile(fileMimeType: FileMimeType): boolean {
  const videoMimeTypes = [
    "video/x-msvideo",
    "video/mp4",
    "video/webm",
    "video/mpeg",
    "audio/midi",
    "audio/mp4",
  ];
  return videoMimeTypes.includes(fileMimeType);
}
