import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-047f1cc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question-item" }
const _hoisted_2 = { class: "sub-text-s2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_button_text = _resolveComponent("button-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.question.title), 1),
    _createVNode(_component_button_text, {
      "is-icon": true,
      padding: "0",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.deleteQna()))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_close_icon, { "fill-color": "#818287" })
      ]),
      _: 1
    })
  ]))
}