
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  ref,
} from "vue";
import PageHeader from "@/components/console/headers/PageHeader.vue";
import InputTextBox from "@/components/console/inputs/InputTextBox.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import {
  LectureCategories,
  LectureForm,
  QuestionForm,
  UpdateLectureForm,
} from "@/types/lectures";
import FileStackService from "@/services/FileStackService.js";
import { FileType, UploadFile } from "@/types/common";
import HelpTooltip from "@/components/console/tooltip/HelpTooltip.vue";
import { fileSizeWithUnit, isVideoTypeFile } from "@/helper/file";
import InputBasic from "@/components/console/inputs/InputBasic.vue";
import OpenHourEditor from "@/pages/console/OpenHourInfo/OpenHourEditor/OpenHourEditor.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import { useStore } from "vuex";
import QuestionItem from "@/pages/console/OpenHourInfo/QuestionItem/QuestionItem.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import InputCheckbox from "@/components/console/inputs/InputCheckbox.vue";
import ButtonAccordion from "@/components/console/buttons/ButtonAccordion.vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import PageLoading from "@/components/console/loadings/PageLoading.vue";
import CheckIcon from "@/components/console/icons/CheckIcon.vue";
import swal from "@/helper/swal.js";
import OpenHourInputFile from "@/pages/console/OpenHourInfo/OpenHourInputFile/OpenHourInputFile.vue";
import SsoService from "../../../services/SsoService.js";
import InputSelectNew from "@/components/console/inputs/InputSelectNew.vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "OpenHourInfo",
  components: {
    InputSelectNew,
    OpenHourInputFile,
    PageLoading,
    CloseIcon,
    ButtonAccordion,
    InputCheckbox,
    ArrowIcon,
    QuestionItem,
    ButtonBasic,
    OpenHourEditor,
    InputBasic,
    HelpTooltip,
    CardBasic,
    InputTextBox,
    PageHeader,
    CheckIcon,
  },
  setup() {
    const store = useStore();
    const { proxy } = getCurrentInstance();

    const introHostPlaceholder = `프로님을 소개할 수 있는 대표 이력을 소개해주세요.\n(예: '커리어 부자되기' 베스트 셀러 저자<2023>, 네카라쿠배 리드 PO<2020~>)`;
    const featuredImageAccepts = ["jpg", "jpeg", "png"] as FileType[];
    const featuredVideoAccepts = ["jpg", "jpeg", "png", "mp4"] as FileType[];
    const openHourInfoElement = ref(null);

    const state = reactive({
      pageLoading: true,
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      isOpenHour: computed(() => {
        return !!state.user.userProfile.defaultCoin;
      }),
      isPrePro: computed(() => {
        return store.getters["auth/isPrePro"];
      }),
      isPro: computed(() => {
        return store.getters["auth/isPro"];
      }),
      lecture: computed(() => {
        return store.getters["lectures/getLecture"];
      }),
      lectureCategories: computed(() => {
        return store.getters["lectures/lectureCategories"];
      }),
      lectureTags: computed(() => {
        return store.getters["lectures/lectureTags"];
      }),
      selectedLectureCategories: {
        id: null,
        name: "",
        categories: [],
      },
      selectedLectureCategoriesSubCategories: [],
      showTagsAccordion: false,
      showSubTagsAccordion: false,
      categoriesForm: {
        id: null,
        name: "",
        categories: [],
      } as any,
      lectureForm: {
        title: "",
        description: "",
        featuredImage: "",
        featuredVideo: "",
        introHost: "",
        featuredVideoThumbnail: "",
        contentImage: "",
        tags: [],
        questions: [] as QuestionForm[],
        categories: { depth1: [], depth2: [] } as LectureCategories | any,
      } as LectureForm,
      uploadedFeaturedImageUrl: "",
      uploadedFeaturedImageFileInfo: {} as UploadFile,
      uploadedFeaturedVideoUrl: "",
      uploadedFeaturedVideoFileInfo: {} as UploadFile,
      uploadedVideoThumbnailUrl: "",
      uploadedVideoThumbnailFileInfo: {} as UploadFile,
      uploadedContentImageUrl: "",
      uploadedContentImageFileInfo: {} as UploadFile,
      activeSaveBtn: computed(() => {
        return (
          state.categoriesForm.categories.length > 0 &&
          state.lectureForm.tags?.length > 0 &&
          !!state.lectureForm.introHost &&
          state.lectureForm.featuredImage &&
          (state.lectureForm.featuredVideo ||
            state.lectureForm.featuredVideoThumbnail) &&
          !!state.lectureForm.title &&
          state.lectureForm.questions.length > 0
        );
      }),
      saveLoading: false,
      searchingText: "",
      subCategoriesMessage: computed(() => {
        return state.categoriesForm?.categories?.length > 0
          ? "최대 3개까지 선택할 수 있어요."
          : "메인 카테고리를 선택해 주세요";
      }),
    });

    onBeforeMount(async () => {
      if (state.user.lecture) {
        await getLecture();
      }
      await getLectureTags();
      await getLectureCategories();
      state.pageLoading = false;
      document.getElementById("app").style.scrollBehavior = "smooth";
    });

    onBeforeUnmount(() => {
      document.getElementById("app").style.scrollBehavior = "auto";
    });

    const getLecture = async () => {
      await store
        .dispatch("lectures/getLecture", {
          resourceId: state.user.lecture.resourceId,
        })
        .then(() => {
          const lecture = cloneDeep(state.lecture);
          setLectureForm(lecture);
          setSelectedCategory(lecture);
        });
    };

    const getLectureTags = async () => {
      await store.dispatch("lectures/getLectureTags");
    };

    const getLectureCategories = async () => {
      await store.dispatch("lectures/getLectureCategories");
    };

    const resetCategories = () => {
      state.categoriesForm = {
        id: null,
        name: "",
        categories: [],
      };
    };

    const setLectureForm = (lecture) => {
      state.lectureForm.title = lecture.title;
      state.lectureForm.description = lecture.description;
      state.lectureForm.introHost = lecture.introHost ? lecture.introHost : "";

      if (lecture.categories) {
        resetCategories();
        state.categoriesForm.id = lecture.categories.id;
        state.categoriesForm.name = lecture.categories.name;
        state.categoriesForm.categories = lecture.categories.categories;

        // lectureForm 구성
        state.lectureForm.categories.depth1 = [];
        state.lectureForm.categories.depth2 = [];
        state.lectureForm.categories.depth1.push(lecture.categories.id);
        lecture.categories.categories.forEach((item) => {
          state.lectureForm.categories.depth2.push(item.id);
        });
      } else {
        resetCategories();
        state.lectureForm.categories.depth1 = [];
        state.lectureForm.categories.depth2 = [];
      }

      if (lecture.featuredImage) {
        state.lectureForm.featuredImage = lecture.featuredImage;
        state.uploadedFeaturedImageFileInfo = {
          name: "썸네일",
          size: "",
        };
      }

      if (lecture.contentImage) {
        state.lectureForm.contentImage = lecture.contentImage;
        state.uploadedContentImageFileInfo = {
          name: "상세 페이지",
          size: "",
        };
      }

      if (lecture.featuredVideo) {
        state.lectureForm.featuredVideo = lecture.featuredVideo;
        state.uploadedFeaturedVideoFileInfo = {
          name: "비디오 파일",
          size: "",
        };
      }

      if (lecture.featuredVideoThumbnail) {
        state.lectureForm.featuredVideoThumbnail =
          lecture.featuredVideoThumbnail;
        state.uploadedVideoThumbnailFileInfo = {
          name: "비디오 썸네일",
          size: "",
        };
      }

      state.lectureForm.tags = lecture.tags.map((item) => {
        return item.name;
      });

      state.lectureForm.questions = [];
      let questionCount = lecture.questions.length;
      for (let i = 0; i < questionCount; i++) {
        state.lectureForm.questions.push({
          title: lecture.questions[i].localization
            ? lecture.questions[i].localization.title
            : "",
          resourceId: lecture.questions[i].resourceId,
        });
      }
    };

    const setSelectedCategory = (lecture) => {
      if (lecture.categories) {
        state.selectedLectureCategories.id = lecture.categories.id;
        state.selectedLectureCategories.name = lecture.categories.name;
        state.selectedLectureCategories.categories =
          lecture.categories.categories;
        state.selectedLectureCategories.categories.forEach((item, index) => {
          state.selectedLectureCategoriesSubCategories[index] = item;
        });
      }
    };

    const putLecture = (): void => {
      let dataPayload: LectureForm = {
        title: state.lectureForm.title,
        description: state.lectureForm.description,
        introHost: state.lectureForm.introHost,
        tags: state.lectureForm.tags,
        questions: state.lectureForm.questions,
        categories: { depth1: [], depth2: [] } as any,
      };

      dataPayload.categories.depth1.push(state.categoriesForm.id);
      state.categoriesForm.categories.forEach((item) => {
        dataPayload.categories.depth2.push(item.id);
      });

      if (state.uploadedFeaturedVideoFileInfo) {
        dataPayload.featuredVideo = state.lectureForm.featuredVideo;
      }
      if (state.uploadedFeaturedImageFileInfo) {
        dataPayload.featuredImage = state.lectureForm.featuredImage;
      }
      if (state.uploadedVideoThumbnailFileInfo) {
        dataPayload.featuredVideoThumbnail =
          state.lectureForm.featuredVideoThumbnail;
      }
      if (state.uploadedContentImageFileInfo) {
        dataPayload.contentImage = state.lectureForm.contentImage;
      }

      let payload: UpdateLectureForm = {
        resourceId: state.lecture.resourceId,
        data: dataPayload,
      };

      store.dispatch("lectures/putLectures", payload).then(() => {
        swal.messageAlert("오픈아워 프로 정보가 등록되었어요.");
        setLectureForm(state.lecture);
        state.saveLoading = false;
      });
    };

    const postLectures = (): void => {
      let payload: LectureForm = {
        title: state.lectureForm.title,
        description: state.lectureForm.description,
        featuredImage: state.lectureForm.featuredImage,
        contentImage: state.lectureForm.contentImage,
        featuredVideo: state.lectureForm.featuredVideo,
        featuredVideoThumbnail: state.lectureForm.featuredVideoThumbnail,
        introHost: state.lectureForm.introHost,
        tags: state.lectureForm.tags,
        questions: state.lectureForm.questions,
        categories: {
          depth1: [],
          depth2: [],
        },
      };

      payload.categories.depth1.push(state.categoriesForm.id);
      state.categoriesForm.categories.forEach((item) => {
        payload.categories.depth2.push(item.id);
      });

      if (payload.categories.depth1.length === 0) {
        return;
      }

      if (payload.categories.depth2.length === 0) {
        return;
      }

      store.dispatch("lectures/postLectures", payload).then(() => {
        swal.messageAlert("오픈아워 프로 정보가 등록되었어요.");
        setLectureForm(state.lecture);
        let payaload = { data: { type: 22 } };
        console.log(payaload);
        store.dispatch("auth/putUser", payaload).then(() => {
          store.dispatch("auth/fetchUser");
          state.saveLoading = false;
        });
      });
    };

    const popSubCategories = (value) => {
      let index = state.categoriesForm.categories.findIndex((item) => {
        return item.id === value.id;
      });

      state.categoriesForm.categories.splice(index, 1);
    };
    const popSelectedTag = (tagName) => {
      let lectureFormTagIndex = state.lectureForm.tags.findIndex((item) => {
        return item === tagName;
      });

      state.lectureForm.tags.splice(lectureFormTagIndex, 1);
    };

    const resetFeaturedVideo = () => {
      state.uploadedFeaturedVideoUrl = "";

      if (state.lecture.featuredVideo) {
        state.lectureForm.featuredVideo = state.lecture.featuredVideo;
        state.uploadedFeaturedVideoFileInfo = {
          name: "비디오 파일",
          size: "",
        };
      } else {
        state.lectureForm.featuredVideo = "";
        state.uploadedFeaturedVideoFileInfo = null;
      }
    };

    const resetVideoThumbnail = () => {
      state.uploadedVideoThumbnailUrl = "";

      if (state.lecture.featuredVideoThumbnail) {
        state.lectureForm.featuredVideoThumbnail =
          state.lecture.featuredVideoThumbnail;
        state.uploadedFeaturedVideoFileInfo = {
          name: "비디오 썸네일",
          size: "",
        };
      } else {
        state.lectureForm.featuredVideoThumbnail = "";
        state.uploadedVideoThumbnailFileInfo = null;
      }
    };

    //todo 코드 리뷰 후 삭제
    const resetFeaturedImage = () => {
      state.uploadedFeaturedImageUrl = "";

      if (state.lecture.featuredImage) {
        state.lectureForm.featuredImage = state.lecture.featuredImage;
        state.uploadedFeaturedImageFileInfo = {
          name: "썸네일",
          size: "",
        };
      } else {
        state.lectureForm.featuredImage = "";
        state.uploadedFeaturedImageFileInfo = null;
      }
    };

    const popFeaturedVideo = () => {
      state.uploadedFeaturedVideoUrl = "";
      state.lectureForm.featuredVideo = "";
      state.uploadedFeaturedVideoFileInfo = null;
    };

    const popVideoThumbnail = () => {
      state.uploadedVideoThumbnailUrl = "";
      state.lectureForm.featuredVideoThumbnail = "";
      state.uploadedVideoThumbnailFileInfo = null;
    };

    const resetSelectedSubCategories = () => {
      state.categoriesForm.categories = [];
    };

    const actions = {
      openThumbnailFileStack: () => {
        const mimeTypes = featuredImageAccepts.map((item) => {
          return proxy.$const.mimeTypes[item].value;
        });

        const fileStack = new FileStackService();
        fileStack.options.transformations.crop.aspectRatio = 10 / 7;
        fileStack.options.accept = mimeTypes;

        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          state.uploadedFeaturedImageUrl = fileMetaData.url;
          state.lectureForm.featuredImage = fileMetaData.url;
          state.uploadedFeaturedImageFileInfo = {
            name: fileMetaData.filename,
            size: fileSizeWithUnit(fileMetaData.size),
          };
        };

        fileStack.open(fileStack.options);
      },
      openFeaturedFileStack: () => {
        const mimeTypes = featuredVideoAccepts.map((item) => {
          return proxy.$const.mimeTypes[item].value;
        });

        const fileStack = new FileStackService();
        fileStack.options.transformations.crop.aspectRatio = 9 / 16;
        fileStack.options.accept = mimeTypes;

        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          if (isVideoTypeFile(fileMetaData.mimetype)) {
            // 비디오 파일이 업로드 되었을 때
            if (state.uploadedFeaturedVideoFileInfo) {
              resetFeaturedVideo();
            }

            // 비디오 썸네일 이미지가 존재하는 경우, 삭제
            if (state.uploadedVideoThumbnailFileInfo) {
              popVideoThumbnail();
            }

            state.uploadedFeaturedVideoUrl = fileMetaData.url;
            state.lectureForm.featuredVideo = fileMetaData.url;

            state.uploadedFeaturedVideoFileInfo = {
              name: fileMetaData.filename,
              size: fileSizeWithUnit(fileMetaData.size),
            };
          } else {
            // 비디오 썸네일 이미지가 업로드 되었을 때
            if (state.uploadedVideoThumbnailFileInfo) {
              resetVideoThumbnail();
            }

            // 비디오 파일이 존재하는 경우, 삭제
            if (state.uploadedFeaturedVideoFileInfo) {
              popFeaturedVideo();
            }

            state.uploadedVideoThumbnailUrl = fileMetaData.url;
            state.lectureForm.featuredVideoThumbnail = fileMetaData.url;

            state.uploadedVideoThumbnailFileInfo = {
              name: fileMetaData.filename,
              size: fileSizeWithUnit(fileMetaData.size),
            };
          }
        };

        fileStack.open(fileStack.options);
      },
      openContentImageFileStack: () => {
        const mimeTypes = featuredImageAccepts.map((item) => {
          return proxy.$const.mimeTypes[item].value;
        });

        const fileStack = new FileStackService();
        fileStack.options.transformations.crop = false;
        fileStack.options.accept = mimeTypes;

        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          state.uploadedContentImageUrl = fileMetaData.url;
          state.lectureForm.contentImage = fileMetaData.url;
          state.uploadedContentImageFileInfo = {
            name: fileMetaData.filename,
            size: fileSizeWithUnit(fileMetaData.size),
          };
        };

        fileStack.open(fileStack.options);
      },
      deleteContentImage: () => {
        state.lectureForm.contentImage = "";
        state.uploadedContentImageFileInfo = {
          name: "상세 페이지",
          size: "",
        };
      },
      updateTags: (value) => {
        if (
          state.lectureForm.tags?.length > 0 &&
          state.lectureForm.tags.includes(value)
        ) {
          popSelectedTag(value);
        } else {
          state.lectureForm.tags.push(value);
        }
      },
      updateSubCategories: (value) => {
        if (
          state.categoriesForm.categories.length > 0 &&
          state.categoriesForm.categories.findIndex((item) => {
            return item.id === value.id;
          }) !== -1
        ) {
          popSubCategories(value);
        } else {
          state.categoriesForm.categories.push(value);
        }
      },
      updateOpenHourTitle: (value) => {
        state.lectureForm.title = value;
      },
      updateLectureDescription: (value) => {
        state.lectureForm.description = value;
      },
      toggleTagsAccordion: () => {
        state.showTagsAccordion = !state.showTagsAccordion;
      },
      toggleSubTagsAccordion: () => {
        state.showSubTagsAccordion = !state.showSubTagsAccordion;
      },
      saveLecture: () => {
        state.saveLoading = true;
        if (state.user.lecture) {
          // 업데이트
          putLecture();
        } else {
          // 생성
          postLectures();
        }
      },
      addQuestionForm: (title) => {
        state.lectureForm.questions.push({
          title: title,
        });
      },
      deleteQna: (question) => {
        const questionFormIndex = state.lectureForm.questions.findIndex(
          (item) => {
            return item.title === question.title;
          }
        );
        if (questionFormIndex > -1) {
          state.lectureForm.questions.splice(questionFormIndex, 1);
        }
      },
      ssoLoginBigcChat: () => {
        const chatUrl = process.env.VUE_APP_CHAT_URL;
        const ssoEndPoint = `${chatUrl}/auth/sso`;

        SsoService.redirect(ssoEndPoint, chatUrl);
      },
      selectLectureCategories: (value) => {
        if (state.selectedLectureCategories.id) {
          if (state.selectedLectureCategories.id !== value.id) {
            state.selectedLectureCategoriesSubCategories = [];
            resetSelectedSubCategories();
          }
        }

        state.categoriesForm.id = value.id;
        state.categoriesForm.name = value.name;
        state.categoriesForm.categories = [];
        state.selectedLectureCategories = value;
        state.selectedLectureCategoriesSubCategories = value.categories;
      },
      updateSearchingText: (text) => {
        state.searchingText = text;
      },
    };

    return {
      state,
      actions,
      featuredImageAccepts,
      featuredVideoAccepts,
      popSelectedTag,
      popSubCategories,
      introHostPlaceholder,
      openHourInfoElement,
    };
  },
});
