
import { defineComponent, PropType, reactive } from "vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";

type QuestionForm = {
  resourceId?: string;
  title: string;
};

export default defineComponent({
  name: "QuestionItem",
  components: { ButtonText, CloseIcon },
  props: {
    question: {
      type: Object as PropType<QuestionForm>,
      required: true,
    },
    number: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  emits: ["deleteQna"],
  setup(props, { emit }) {
    const state = reactive({
      isEditMode: false,
      questionTitle: "",
    });

    const actions = {
      deleteQna: () => {
        emit("deleteQna");
      },
    };

    return { state, actions };
  },
});
