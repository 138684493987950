<template>
  <icon-base
    icon-name="image-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 3H3L3 9.04273L5.60532 5.69303C5.701 5.57002 5.84856 5.49865 6.00439 5.50002C6.16022 5.50139 6.3065 5.57533 6.4 5.7L9.0541 9.2388L10.6464 7.64645C10.8417 7.45118 11.1583 7.45118 11.3536 7.64645L13 9.29289V3ZM3 12.5V10.6716L5.99277 6.8237L8.6 10.3C8.68697 10.416 8.81997 10.4885 8.96456 10.4987C9.10914 10.509 9.25106 10.456 9.35355 10.3536L11 8.70711L13 10.7071V12.5C13 12.7761 12.7761 13 12.5 13H3.5C3.22386 13 3 12.7761 3 12.5ZM2 3V10.5V12.5V13C2 13.5523 2.44772 14 3 14H3.5H12.5H13C13.5523 14 14 13.5523 14 13V12.5V10.5V3C14 2.44772 13.5523 2 13 2H3C2.44772 2 2 2.44772 2 3ZM11 6C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6Z"
        :fill="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>
    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3ZM12 5C12 5.55228 11.5523 6 11 6C10.4477 6 10 5.55228 10 5C10 4.44772 10.4477 4 11 4C11.5523 4 12 4.44772 12 5ZM6.38555 6.34746C6.18774 6.08371 5.79328 6.08025 5.59087 6.34049L3 9.67162V12.5001C3 12.7762 3.22386 13.0001 3.5 13.0001H12.5C12.7761 13.0001 13 12.7762 13 12.5001V9.70717L11.3536 8.06072C11.1583 7.86546 10.8417 7.86546 10.6464 8.06072L9.35355 9.35361C9.25106 9.45611 9.10914 9.50908 8.96456 9.4988C8.81997 9.48853 8.68697 9.41602 8.6 9.30006L6.38555 6.34746Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "ImageIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#D0D5DE",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
