<template>
  <div ref="selectBoxEl" class="select-box">
    <label v-if="label" class="label">
      <span class="sub-text-s2">{{ label }}</span>
      <span v-if="required" class="text-purple-50">*</span>
    </label>
    <button
      class="select-btn"
      :class="{ 'gray-bg': bgColor === 'gray' }"
      :disabled="disabled"
      @click="actions.toggleSelectList()"
    >
      <span class="sub-text-s2">
        {{ placeholder }}
      </span>
      <arrow-icon direction="down" stroke-size="normal"></arrow-icon>
    </button>
    <div
      ref="selectListWrapperElement"
      class="select-list"
      :style="{
        top: `${listTopGap}px`,
      }"
    >
      <ul
        v-show="state.showList"
        ref="selectListElement"
        class="select-list-wrapper"
      >
        <li
          v-for="item in list"
          :key="`selectable-item-${item.id}`"
          @click="actions.selectItem(item)"
        >
          <p class="sub-text-s2">{{ item.name }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import ArrowIcon from "../icons/ArrowIcon";

export default {
  name: "InputSelectNew",
  components: { ArrowIcon },
  props: {
    selectedItem: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "white",
      validator(value) {
        return ["gray", "white"].includes(value);
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
    maxVisibleList: {
      type: Number,
      default: 6,
    },
    // todo 디자인에서 간격 통일화 시키기
    listTopGap: {
      type: Number,
      default: 8,
    },
    placeholder: {
      type: String,
      default: "값을 선택해주세요.",
    },
    autoHide: {
      type: Boolean,
      default: false,
    },
    wrapperElementClass: {
      type: String,
      default: "",
    },
  },
  emits: ["select"],
  setup(props, { emit }) {
    const selectListWrapperElement = ref();
    const selectListElement = ref();
    const selectBoxEl = ref();
    const state = reactive({
      showList: false,
      listMaxHeight: 0,
      pageElement: null,
      autoClosingList: false,
    });

    // todo test 함수
    onMounted(() => {
      if (props.wrapperElementClass) {
        state.pageElement = document.querySelector(
          `.${props.wrapperElementClass}`
        );
        state.pageElement.addEventListener("click", (e) => {
          clickEvent(e);
        });
      }
    });

    const clickEvent = (e) => {
      if (
        state.autoClosingList &&
        state.showList &&
        !selectListWrapperElement.value.contains(e.target)
      ) {
        // state.showList = false;
        state.showList = false;
      }
      state.autoClosingList = true;
    };

    const actions = {
      toggleSelectList: () => {
        if (!state.showList) {
          selectListElement.value.style.maxHeight = `${
            props.maxVisibleList * 48
          }px`;
          state.showList = true;
        } else {
          selectListElement.value.style.maxHeight = "0px";
          state.showList = false;
        }
        state.autoClosingList = false;
      },
      selectItem: (item) => {
        emit("select", item);
        selectListElement.value.style.maxHeight = "0px";
        state.showList = false;
      },
      closeSelectList: (event) => {
        if (
          !selectBoxEl.value.contains(event.target) &&
          !selectListElement.value.contains(event.target) &&
          state.showList
        ) {
          actions.toggleSelectList();
        }
        // if (state.showList) {
        //   console.log("state.showList", state.showList);
        //   // if (!selectListElement.value.contains(event.target)) {
        //   //   emit("closeModal");
        //   // }
        //   // state.showList = false;
        // }
      },
    };

    return {
      state,
      actions,
      selectListWrapperElement,
      selectListElement,
      selectBoxEl,
    };
  },
};
</script>

<style scoped>
.label {
  display: block;
  margin-bottom: 4px;
}

.select-box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.select-box label {
  margin-bottom: 8px;
}

.select-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 14px 16px;
}
.select-btn.error {
  border: 1px solid #ff4646;
}

.select-btn.gray-bg {
  background: #f6f8fa;
  border: none;
}

.select-list {
  position: absolute;
  width: 100%;
  z-index: 50;
}

.select-list-wrapper {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  list-style: none;
  padding-left: 0;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  overflow-y: scroll;
  padding-top: 6px;
}
.select-list-wrapper li {
  cursor: pointer;
  padding: 11px 15px;
  background-color: #ffffff;
}

.select-list-wrapper li:hover {
  background-color: #f6f8fa;
}

.select-list-wrapper li p {
  margin-bottom: 0;
}
</style>
