<template>
  <button v-if="false" @click="actions.openLink()">Hello.</button>
  <div class="open-hour-editor">
    <!--        Note 기존 에디터 코드  -->
    <div id="openhour-editor-toolbar" ref="toolbar">
      <button class="ql-header" value="1" type="button"></button>
      <button class="ql-header" value="2" type="button"></button>
      <button class="ql-header" value="3" type="button"></button>
      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>
      <button class="ql-bold"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
      <button class="ql-hl"></button>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <button type="button" class="ql-list" value="bullet"></button>
      <button type="button" class="ql-list" value="ordered"></button>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <button type="button" class="ql-code-block"></button>
      <button type="button" class="ql-image"></button>
      <button type="button" class="ql-link"></button>
      <!--      <button-->
      <!--        type="button"-->
      <!--        class="ql-custom-link"-->
      <!--        @click="actions.openLink()"-->
      <!--      ></button>-->
    </div>

    <div
      id="lecture-editor"
      ref="lectureEditor"
      :style="{
        minHeight: '240px',
      }"
    ></div>
  </div>
  <div
    ref="customInput"
    class="custom-input"
    :class="{ isActive: state.isCustomInput }"
  >
    <input v-model="state.url" type="text" />
    <button @click="actions.createLink()">Hi</button>
  </div>
  <teleport to="#teleport">
    <base-modal-new v-if="false">
      <template #modalBody>
        <input
          type="url"
          placeholder="동영상 url 입력 후 엔터"
          :value="state.videoUrl"
          @input="actions.updateVideoUrl($event)"
        />
      </template>
    </base-modal-new>
  </teleport>
  <!--  <teleport to="#lecture-editor">-->
  <!--    <div v-if="state.isCustomInput" ref="customInput" class="custom-input">-->
  <!--      <input type="text" />-->
  <!--      <button>Hi</button>-->
  <!--    </div>-->
  <!--  </teleport>-->
</template>

<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import Quill from "quill";
import TextDivider from "@/components/console/dividers/TextDivider.vue";
import Delta from "quill-delta";
import BaseModalNew from "@/components/common/BaseModalNew";
import FileStackService from "@/services/FileStackService";
import ApiService from "@/api";
import Bold from "quill/formats/bold";
import Inline from "quill/blots/inline";

export default defineComponent({
  name: "OpenHourEditor",
  components: { BaseModalNew, TextDivider },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["updateValue"],
  setup(props, { emit }) {
    const lectureEditor = ref();
    // const toolbar = ref();
    const customInput = ref();

    let lectureQuill;

    const icons = {
      h1: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path class="ql-fill" d="M9 4V12H7.98324V8.37303H4.01676V12H3V4H4.01676V7.5122H7.98324V4H9Z" fill="#0D0D10"/>
  <path class="ql-fill" d="M11 4.82303V4H13.1578V11.9964H12.1019V4.82303H11Z" fill="#0D0D10"/>
</svg>`,
      h2: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path class="ql-fill" d="M8 4V12H6.98324V8.37303H3.01676V12H2V4H3.01676V7.5122H6.98324V4H8Z" fill="#0D0D10"/>
  <path class="ql-fill" d="M9 11.2857C9.96812 10.5385 10.7266 9.92674 11.2755 9.45055C11.8243 8.96703 12.2855 8.4652 12.659 7.94506C13.0402 7.41758 13.2308 6.9011 13.2308 6.3956C13.2308 5.91941 13.1088 5.54579 12.8649 5.27473C12.6286 4.99634 12.2436 4.85714 11.71 4.85714C11.1916 4.85714 10.7876 5.01465 10.4979 5.32967C10.2159 5.63736 10.0634 6.05128 10.0405 6.57143H9.0343C9.06479 5.75092 9.32398 5.11722 9.81185 4.67033C10.2997 4.22344 10.9286 4 11.6985 4C12.4837 4 13.105 4.20879 13.5624 4.62637C14.0274 5.04396 14.2599 5.61905 14.2599 6.35165C14.2599 6.95971 14.0693 7.55311 13.6881 8.13187C13.3146 8.7033 12.8877 9.20879 12.4075 9.64835C11.9272 10.0806 11.3136 10.5861 10.5665 11.1648H14.5V12H9V11.2857Z" fill="#0D0D10"/>
</svg>`,
      h3: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path class="ql-fill" d="M8 4V12H6.98324V8.37303H3.01676V12H2V4H3.01676V7.5122H6.98324V4H8Z" fill="#0D0D10"/>
  <path class="ql-fill" d="M9.1186 6.11321C9.16891 5.45193 9.42408 4.93441 9.8841 4.56065C10.3441 4.18688 10.9407 4 11.6739 4C12.1626 4 12.5831 4.08985 12.9353 4.26954C13.2947 4.44205 13.5642 4.67925 13.7439 4.98113C13.9308 5.28302 14.0243 5.62444 14.0243 6.00539C14.0243 6.45103 13.8949 6.83558 13.6361 7.15903C13.3845 7.48248 13.0539 7.69093 12.6442 7.78437V7.83827C13.1114 7.95328 13.4816 8.17969 13.7547 8.51752C14.0279 8.85535 14.1644 9.29739 14.1644 9.84367C14.1644 10.2534 14.071 10.6235 13.8841 10.9542C13.6972 11.2776 13.4169 11.5328 13.0431 11.7197C12.6694 11.9066 12.2201 12 11.6954 12C10.9335 12 10.3082 11.8023 9.81941 11.407C9.33064 11.0045 9.0575 10.4367 9 9.7035H9.94879C9.9991 10.1348 10.1752 10.487 10.4771 10.7601C10.779 11.0332 11.1815 11.1698 11.6846 11.1698C12.1878 11.1698 12.5687 11.0404 12.8275 10.7817C13.0934 10.5157 13.2264 10.1743 13.2264 9.75741C13.2264 9.21833 13.0467 8.83019 12.6873 8.59299C12.3279 8.35579 11.7853 8.2372 11.0593 8.2372H10.8113V7.41779H11.0701C11.7314 7.4106 12.2309 7.30279 12.5687 7.09434C12.9066 6.87871 13.0755 6.54807 13.0755 6.10243C13.0755 5.72147 12.9497 5.41599 12.6981 5.18598C12.4537 4.95597 12.1015 4.84097 11.6415 4.84097C11.1959 4.84097 10.8365 4.95597 10.5633 5.18598C10.2902 5.41599 10.1285 5.72507 10.0782 6.11321H9.1186Z" fill="#0D0D10"/>
</svg>`,
      bold: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path class="ql-fill" d="M3.5 12.5V11.7815H3.52332C3.66413 11.7815 3.78482 11.7563 3.8854 11.7059C3.99604 11.6555 4.0765 11.5756 4.12679 11.4664C4.18714 11.3571 4.21731 11.2101 4.21731 11.0252V4.96218C4.21731 4.7605 4.18211 4.60924 4.1117 4.5084C4.0413 4.39916 3.95078 4.32353 3.84014 4.28151C3.7295 4.2395 3.61887 4.21849 3.50823 4.21849H3.5V3.5H7.52134C9.00991 3.5 10.1364 3.67647 10.9008 4.02941C11.6652 4.37395 12.0474 4.94538 12.0474 5.7437C12.0474 6.12185 11.9518 6.44118 11.7607 6.70168C11.5797 6.95378 11.3283 7.16387 11.0064 7.33193C10.6946 7.4916 10.3426 7.61345 9.95032 7.69748V7.77311C10.4532 7.84874 10.8907 7.98319 11.2629 8.17647C11.6451 8.36975 11.9468 8.61765 12.1681 8.92017C12.3894 9.22269 12.5 9.58824 12.5 10.0168C12.5 10.8403 12.1228 11.4622 11.3685 11.8824C10.6242 12.2941 9.49269 12.5 7.97394 12.5H3.5ZM7.80799 11.6807C8.41146 11.6807 8.85904 11.5546 9.15072 11.3025C9.4424 11.042 9.58824 10.6008 9.58824 9.97899C9.58824 9.57563 9.52789 9.2437 9.40719 8.98319C9.2865 8.72269 9.0954 8.52941 8.83389 8.40336C8.58244 8.26891 8.25053 8.20168 7.83816 8.20168H6.93295V11.6807H7.80799ZM7.43081 7.38235C7.85325 7.38235 8.19019 7.33193 8.44163 7.23109C8.70314 7.12185 8.88921 6.95378 8.99985 6.72689C9.12054 6.4916 9.18089 6.18487 9.18089 5.80672C9.18089 5.2521 9.03505 4.86555 8.74337 4.64706C8.45169 4.42857 8.00411 4.31933 7.40064 4.31933H6.93295V7.38235H7.43081Z" fill="#0D0D10"/>
</svg>`,
      underline: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path class="ql-fill" d="M8.07168 12C7.23953 12 6.52625 11.8911 5.93186 11.6732C5.33747 11.4554 4.88176 11.1097 4.56475 10.6362C4.24774 10.1626 4.08924 9.54225 4.08924 8.77509V4.59124C4.08924 4.32605 4.04466 4.1319 3.9555 4.00877C3.86634 3.87618 3.75242 3.79094 3.61372 3.75305C3.47503 3.71517 3.32148 3.69623 3.15307 3.69623L3 3.69613V3L7.30769 3.0001V3.69623H7.04635C6.87794 3.69623 6.71943 3.7199 6.57083 3.76726C6.43214 3.80514 6.31822 3.89038 6.22906 4.02298C6.14981 4.15557 6.11018 4.36394 6.11018 4.64807V8.87454C6.11018 9.41439 6.20924 9.84532 6.40738 10.1673C6.60551 10.4799 6.87794 10.7072 7.22467 10.8493C7.5714 10.9819 7.97261 11.0482 8.42831 11.0482C8.92364 11.0482 9.33972 10.9677 9.67654 10.8066C10.0233 10.6456 10.2808 10.4041 10.4493 10.0821C10.6276 9.76008 10.7167 9.37177 10.7167 8.91716V4.59124C10.7167 4.32605 10.6722 4.1319 10.583 4.00877C10.4938 3.87618 10.3799 3.79094 10.2412 3.75305C10.1025 3.71517 9.94897 3.69623 9.78056 3.69623H9.46154V3.0001H13V3.69623H12.6782C12.5197 3.69623 12.3662 3.7199 12.2176 3.76726C12.069 3.80514 11.9551 3.89038 11.8758 4.02298C11.7965 4.15557 11.7569 4.36394 11.7569 4.64807V8.94557C11.7569 9.57066 11.6232 10.1153 11.3557 10.5793C11.0882 11.0339 10.6821 11.3844 10.1372 11.6306C9.59234 11.8769 8.90383 12 8.07168 12Z" fill="#0D0D10"/>
  <rect class="ql-fill" x="2" y="13" width="12" height="1" fill="#0D0D10"/>
</svg>`,
      del: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <rect class="ql-fill" x="2" y="8" width="12" height="1" fill="#0D0D10"/>
  <path class="ql-fill" d="M5.80984 14V13H6.26885C6.42623 13 6.56612 12.9851 6.68852 12.9552C6.81093 12.9178 6.9071 12.8469 6.97705 12.7423C7.05574 12.6377 7.09508 12.4771 7.09508 12.2605V4H4.96721C4.70492 4 4.49945 4.03735 4.35082 4.11204C4.20219 4.17927 4.0929 4.27638 4.02295 4.40336C3.95301 4.53035 3.90492 4.676 3.87869 4.84034L3.8 4.94729H3L3.06557 3H12.9344L13 4.94729H12.2L12.1082 4.84034C12.0907 4.676 12.0426 4.53035 11.9639 4.40336C11.894 4.27638 11.7847 4.17927 11.6361 4.11204C11.4874 4.03735 11.282 4 11.0197 4H8.8918V12.2493C8.8918 12.4659 8.92678 12.6303 8.99672 12.7423C9.06667 12.8469 9.16721 12.9178 9.29836 12.9552C9.42951 12.9851 9.5694 13 9.71803 13H10.177V14H5.80984Z" fill="#0D0D10"/>
</svg>`,
      hl: `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <rect x="2" y="13" width="12" height="1" fill="#E93A43"/>
  <path class="ql-fill" fill-rule="evenodd" clip-rule="evenodd" d="M10.5941 2.13313C10.4166 1.95562 10.1288 1.95562 9.95132 2.13313L3.13313 8.95132C3.04789 9.03656 3 9.15217 3 9.27273V11.5455C3 11.7965 3.20351 12 3.45455 12H5.72727C5.84783 12 5.96344 11.9521 6.04868 11.8669L12.8669 5.04868C13.0444 4.87117 13.0444 4.58337 12.8669 4.40586L10.5941 2.13313ZM3.90909 11.0909V9.46101L8.56729 4.8028L10.1972 6.43271L5.53899 11.0909H3.90909ZM10.84 5.78988L11.9026 4.72727L10.2727 3.09737L9.21012 4.15998L10.84 5.78988Z" fill="#0D0D10"/>
</svg>
      `,
      listBullet: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <rect class="ql-fill" x="5" y="3" width="9" height="1" rx="0.5" fill="#0D0D10"/>
  <circle class="ql-fill" cx="3" cy="3.5" r="1" fill="#0D0D10"/>
  <rect class="ql-fill" x="5" y="7.5" width="9" height="1" rx="0.5" fill="#0D0D10"/>
  <circle class="ql-fill" cx="3" cy="8" r="1" fill="#0D0D10"/>
  <rect class="ql-fill" x="5" y="12" width="9" height="1" rx="0.5" fill="#0D0D10"/>
  <circle class="ql-fill" cx="3" cy="12.5" r="1" fill="#0D0D10"/>
</svg>`,
      listOrdered: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <rect class="ql-fill" x="5" y="3" width="9" height="1" rx="0.5" fill="#0D0D10"/>
  <rect class="ql-fill" x="5" y="7.5" width="9" height="1" rx="0.5" fill="#0D0D10"/>
  <rect class="ql-fill" x="5" y="12" width="9" height="1" rx="0.5" fill="#0D0D10"/>
  <path class="ql-fill" d="M2.51709 2.977V2.5H3.34809V4.69H2.81409V2.977H2.51709Z" fill="#0D0D10"/>
  <path class="ql-fill" d="M2.25991 8.58921C2.32791 8.53521 2.35891 8.51021 2.35291 8.51421C2.54891 8.35221 2.70291 8.21921 2.81491 8.11521C2.92891 8.01121 3.02491 7.90221 3.10291 7.78821C3.18091 7.67421 3.21991 7.56321 3.21991 7.45521C3.21991 7.37321 3.20091 7.30921 3.16291 7.26321C3.12491 7.21721 3.06791 7.19421 2.99191 7.19421C2.91591 7.19421 2.85591 7.22321 2.81191 7.28121C2.76991 7.33721 2.74891 7.41721 2.74891 7.52121H2.25391C2.25791 7.35121 2.29391 7.20921 2.36191 7.09521C2.43191 6.98121 2.52291 6.89721 2.63491 6.84321C2.74891 6.78921 2.87491 6.76221 3.01291 6.76221C3.25091 6.76221 3.42991 6.82321 3.54991 6.94521C3.67191 7.06721 3.73291 7.22621 3.73291 7.42221C3.73291 7.63621 3.65991 7.83521 3.51391 8.01921C3.36791 8.20121 3.18191 8.37921 2.95591 8.55321H3.76591V8.97021H2.25991V8.58921Z" fill="#0D0D10"/>
  <path class="ql-fill" d="M2.24477 12.169C2.25277 11.955 2.32277 11.79 2.45477 11.674C2.58677 11.558 2.76577 11.5 2.99177 11.5C3.14177 11.5 3.26977 11.526 3.37577 11.578C3.48377 11.63 3.56477 11.701 3.61877 11.791C3.67477 11.881 3.70277 11.982 3.70277 12.094C3.70277 12.226 3.66977 12.334 3.60377 12.418C3.53777 12.5 3.46077 12.556 3.37277 12.586V12.598C3.48677 12.636 3.57677 12.699 3.64277 12.787C3.70877 12.875 3.74177 12.988 3.74177 13.126C3.74177 13.25 3.71277 13.36 3.65477 13.456C3.59877 13.55 3.51577 13.624 3.40577 13.678C3.29777 13.732 3.16877 13.759 3.01877 13.759C2.77877 13.759 2.58677 13.7 2.44277 13.582C2.30077 13.464 2.22577 13.286 2.21777 13.048H2.71577C2.71777 13.136 2.74277 13.206 2.79077 13.258C2.83877 13.308 2.90877 13.333 3.00077 13.333C3.07877 13.333 3.13877 13.311 3.18077 13.267C3.22477 13.221 3.24677 13.161 3.24677 13.087C3.24677 12.991 3.21577 12.922 3.15377 12.88C3.09377 12.836 2.99677 12.814 2.86277 12.814H2.76677V12.397H2.86277C2.96477 12.397 3.04677 12.38 3.10877 12.346C3.17277 12.31 3.20477 12.247 3.20477 12.157C3.20477 12.085 3.18477 12.029 3.14477 11.989C3.10477 11.949 3.04977 11.929 2.97977 11.929C2.90377 11.929 2.84677 11.952 2.80877 11.998C2.77277 12.044 2.75177 12.101 2.74577 12.169H2.24477Z" fill="#0D0D10"/>
</svg>`,
      codeBlock: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path class="ql-fill" fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12 2.72386 12.2239 2.5 12.5 2.5H14C14.2761 2.5 14.5 2.72386 14.5 3V13.5C14.5 13.7761 14.2761 14 14 14H3C2.72386 14 2.5 13.7761 2.5 13.5V10.5C2.5 10.2239 2.72386 10 3 10C3.27614 10 3.5 10.2239 3.5 10.5V13H13.5V3.5H12.5C12.2239 3.5 12 3.27614 12 3Z" fill="#0D0D10"/>
  <path class="ql-stroke" style="stroke-width: 1" d="M7 2.5L5.5 7.5" stroke="#0D0D10" stroke-linecap="round"/>
  <path class="ql-stroke" style="stroke-width: 1" d="M3.5 3L2 5L3.5 7" stroke="#0D0D10" stroke-linecap="round" stroke-linejoin="round"/>
  <path class="ql-stroke" style="stroke-width: 1" d="M9 3L10.5 5L9 7" stroke="#0D0D10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
      image: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path class="ql-fill" fill-rule="evenodd" clip-rule="evenodd" d="M13 3H3L3 9.04273L5.60532 5.69303C5.701 5.57002 5.84856 5.49865 6.00439 5.50002C6.16022 5.50139 6.3065 5.57533 6.4 5.7L9.0541 9.2388L10.6464 7.64645C10.8417 7.45118 11.1583 7.45118 11.3536 7.64645L13 9.29289V3ZM3 12.5V10.6716L5.99277 6.8237L8.6 10.3C8.68697 10.416 8.81997 10.4885 8.96456 10.4987C9.10914 10.509 9.25106 10.456 9.35355 10.3536L11 8.70711L13 10.7071V12.5C13 12.7761 12.7761 13 12.5 13H3.5C3.22386 13 3 12.7761 3 12.5ZM2 3V10.5V12.5V13C2 13.5523 2.44772 14 3 14H3.5H12.5H13C13.5523 14 14 13.5523 14 13V12.5V10.5V3C14 2.44772 13.5523 2 13 2H3C2.44772 2 2 2.44772 2 3ZM11 6C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6Z" fill="#0D0D10"/>
</svg>`,
      link: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path class="ql-fill" fill-rule="evenodd" clip-rule="evenodd" d="M13.2515 2.74852C12.2547 1.75179 10.6387 1.75179 9.64198 2.74853L7.60125 4.78926C7.40599 4.98452 7.40599 5.3011 7.60125 5.49637C7.79651 5.69163 8.11309 5.69163 8.30836 5.49637L10.3491 3.45563C10.9553 2.84942 11.9382 2.84942 12.5444 3.45563C13.1506 4.06184 13.1506 5.04469 12.5444 5.6509L9.85966 8.3356C9.25345 8.94181 8.2706 8.94181 7.66439 8.3356C7.46913 8.14034 7.15255 8.14034 6.95728 8.3356C6.76202 8.53086 6.76202 8.84745 6.95728 9.04271C7.95402 10.0394 9.57004 10.0394 10.5668 9.04271L13.2515 6.35801C14.2482 5.36128 14.2482 3.74526 13.2515 2.74852ZM2.74853 13.2513C3.74526 14.2481 5.36128 14.248 6.35801 13.2513L8.39874 11.2106C8.594 11.0153 8.594 10.6987 8.39874 10.5035C8.20348 10.3082 7.8869 10.3082 7.69164 10.5035L5.6509 12.5442C5.04469 13.1504 4.06184 13.1504 3.45563 12.5442C2.84942 11.938 2.84942 10.9551 3.45563 10.3489L6.14033 7.66424C6.74654 7.05804 7.72939 7.05804 8.3356 7.66424C8.53086 7.85951 8.84744 7.85951 9.04271 7.66424C9.23797 7.46898 9.23797 7.1524 9.04271 6.95714C8.04598 5.96041 6.42996 5.96041 5.43322 6.95714L2.74853 9.64184C1.75179 10.6386 1.75179 12.2546 2.74853 13.2513Z" fill="#0D0D10"/>
</svg>`,
    };

    const state = reactive({
      value: props.value ? props.value : "",
      isOpen: false,
      videoUrl: "",
      attachments: [],
      lastSelection: 0,
      isCustomInput: false,
      url: "",
    });

    const isValidURL = (url) => {
      // Regular expression pattern for URL validation
      // let pattern = new RegExp(
      //   "^((https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?)$",
      //   "i"
      // );

      let pattern = new RegExp("^https?://.+");

      // Test the URL against the pattern
      return pattern.test(url);
    };

    onMounted(async () => {
      //note 툴바 아이콘 이미지 커스텀
      let Icons = Quill.import("ui/icons");
      Icons["header"]["1"] = icons.h1;
      Icons["header"]["2"] = icons.h2;
      Icons["header"]["3"] = icons.h3;
      Icons["bold"] = icons.bold;
      Icons["underline"] = icons.underline;
      Icons["strike"] = icons.del;
      Icons["hl"] = icons.hl;
      Icons["list"]["ordered"] = icons.listOrdered;
      Icons["list"]["bullet"] = icons.listBullet;
      Icons["code-block"] = icons.codeBlock;
      Icons["image"] = icons.image;
      Icons["link"] = icons.link;
      // Icons["custom-link"] = icons.link;

      Quill.register("ui/icons", Icons);

      let Inline = Quill.import("blots/inline");
      class UnderlineHighlight extends Inline {}
      UnderlineHighlight.blotName = "hl";
      UnderlineHighlight.tagName = "U";
      console.log(UnderlineHighlight);
      UnderlineHighlight.className = "ql-hl-e93a43";

      Quill.register(UnderlineHighlight);

      // let Parchment = Quill.import("parchment");
      //
      // let BackgroundClass = new Parchment.Attributor.Class("hl", "ql-hl", {
      //   scope: Parchment.Scope.INLINE,
      // });
      // Quill.register(BackgroundClass, true);

      let BlockEmbed = Quill.import("blots/block/embed");

      class ImageBlot extends BlockEmbed {
        static create(value) {
          let node = super.create();
          node.setAttribute("alt", value.alt);
          node.setAttribute("src", value.url);
          return node;
        }

        static value(node) {
          // 이미지 넣을 때 사이즈 제한하는 로직.
          // node.style.width = "100%";
          return {
            alt: node.getAttribute("alt"),
            url: node.getAttribute("src"),
          };
        }
      }
      ImageBlot.blotName = "image";
      ImageBlot.tagName = "img";

      Quill.register(ImageBlot);

      let InlineLink = Quill.import("formats/link");
      class Link extends InlineLink {
        static create(value) {
          let node = super.create(value);
          value = this.sanitize(value);
          node.setAttribute("href", value);
          node.setAttribute("rel", "noopener noreferrer");
          node.setAttribute("target", "_blank");
          return node;
        }

        static formats(domNode) {
          return domNode.getAttribute("href");
        }

        static sanitize(url) {
          return isValidURL(url) ? url : `https://${url}`;
        }

        format(name, value) {
          if (name !== this.statics.blotName || !value)
            return super.format(name, value);
          value = this.constructor.sanitize(value);
          this.domNode.setAttribute("href", value);
        }
      }
      Link.blotName = "link";
      Link.tagName = "A";
      Link.SANITIZED_URL = "about:blank";
      Link.PROTOCOL_WHITELIST = ["http", "https", "mailto", "tel"];

      Quill.register("formats/link", Link);

      class CustomLink extends Inline {
        static create(value) {
          let node = super.create(value);
          value = this.sanitize(value);
          node.setAttribute("href", value);
          node.setAttribute("rel", "noopener noreferrer");
          node.setAttribute("target", "_blank");
          return node;
        }

        static formats(domNode) {
          return domNode.getAttribute("href");
        }

        static sanitize(url) {
          return isValidURL(url) ? url : `https://${url}`;
        }

        format(name, value) {
          if (name !== this.statics.blotName || !value)
            return super.format(name, value);
          value = this.constructor.sanitize(value);
          this.domNode.setAttribute("href", value);
        }
      }

      CustomLink.blotName = "custom-link";
      CustomLink.tagName = "A";
      CustomLink.SANITIZED_URL = "about:blank";
      CustomLink.PROTOCOL_WHITELIST = ["http", "https", "mailto", "tel"];

      Quill.register(CustomLink);

      const Clipboard = Quill.import("modules/clipboard");

      class PlainTextClipboard extends Clipboard {
        onPaste(e) {
          if (e.defaultPrevented || !this.quill.isEnabled()) return;
          let range = this.quill.getSelection();
          let delta = new Delta().retain(range.index);
          let scrollTop = this.quill.scrollingContainer.scrollTop;
          this.container.focus();
          this.quill.selection.update(Quill.sources.SILENT);
          setTimeout(() => {
            delta = delta.concat(this.convert()).delete(range.length);
            this.quill.updateContents(delta, Quill.sources.USER);
            // range.length contributes to delta.length()
            this.quill.setSelection(
              delta.length() - range.length,
              Quill.sources.SILENT
            );
            this.quill.scrollingContainer.scrollTop = scrollTop;
            this.quill.focus();
          }, 1);
        }
      }

      Quill.register("modules/clipboard", PlainTextClipboard);

      // let options = {
      //   compatibilityMode: false,
      //   modules: {
      //     toolbar: {
      //       container: toolbar.value,
      //     },
      //   },
      //   debug: false, // 'error', 'warn', 'log', or 'info' , false
      //   placeholder: props.placeholder,
      //   theme: "snow", // bubble or snow
      //   bounds: lectureEditor.value,
      // };

      let EmbedBlock = Quill.import("blots/block/embed");
      class Hr extends EmbedBlock {}
      Hr.blotName = "hr"; //now you can use .ql-hr classname in your toolbar
      Hr.tagName = "hr";
      Quill.register({
        "formats/hr": Hr,
      });

      let options = {
        debug: "error",
        modules: {
          // toolbar: "#quill-custom-toolbar",
          toolbar: {
            container: "#openhour-editor-toolbar",
            handlers: {
              image: function () {
                let range = lectureQuill.getSelection();
                if (range) {
                  if (range.length == 0) {
                    console.log("User cursor is at index", range.index);
                  } else {
                    let text = lectureQuill.getText(range.index, range.length);
                    console.log("User has highlighted: ", text);
                  }
                } else {
                  console.log("User cursor is not in editor");
                  return;
                }

                const fileStack = new FileStackService();
                fileStack.options.transformations.crop = true;

                fileStack.options.onFileUploadFinished = (fileMetaData) => {
                  const formData = new FormData();
                  formData.append("file", fileMetaData.url);

                  ApiService.postAttachment(formData).then((res) => {
                    if (res.data.success) {
                      state.attachments.push(res.data.data);

                      lectureQuill.insertText(range.index, "\n");

                      lectureQuill.insertEmbed(
                        range.index + 1,
                        "image",
                        {
                          url: res.data.data.url, // any url
                          alt: res.data.data.url,
                        },
                        "user"
                      );

                      lectureQuill.setSelection(range.index + 2);
                    }
                  });
                };
                fileStack.open(fileStack.options);
              },
            },
          },
        },
        theme: "snow",
        bounds: lectureEditor.value,
        scrollingContainer: "#app",
      };

      lectureQuill = await new Quill(lectureEditor.value, options);

      // //note 붙여 넣기 할 때, img inline-style width:100%으로 수정하는 코드
      // lectureQuill.clipboard.addMatcher("img", function (node, delta) {
      //   return delta.compose(
      //     new Delta().retain(delta.length(), { width: "100%" })
      //   );
      // });

      // note 글자 서식 복사 제한하는 로직
      lectureQuill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = [];
        delta.ops.forEach((op) => {
          if (op.insert && typeof op.insert === "string") {
            ops.push({
              insert: op.insert,
            });
          }
        });
        delta.ops = ops;
        return delta;
      });

      lectureQuill.clipboard.addMatcher(Node.TEXT_NODE, function (node, delta) {
        return new Delta().insert(node.data);
      });

      if (props.value) {
        let convertedDelta = htmlToDelta(props.value);
        lectureQuill.setContents(convertedDelta, "api");
      }

      lectureQuill.on("text-change", update);

      lectureQuill.on("selection-change", (range, oldRange, source) => {
        if (range) {
          // if (range.length == 0) {
          //   let currentIndex = range.index;
          //   console.log("User cursor is on", range.index);
          //   let getFormat = lectureQuill.getFormat();
          //   // 1차 검증 , link 있으면 태우기.
          //   // console.log(getFormat.hasOwnProperty("link"));
          //   console.log(typeof getFormat);
          //   let isLinkTag = getFormat.link?.length > 0;
          //   console.log(isLinkTag);
          //   if (isLinkTag) {
          //     let selection = window.getSelection();
          //     let startOffSet = selection.getRangeAt(0).startOffset;
          //     let startIndex = currentIndex - startOffSet;
          //     let textLength =
          //       selection.getRangeAt(0).startContainer.textContent.length;
          //     const contents = lectureQuill.getContents();
          //     // contents.ops[startIndex].insert = newText;
          //     // lectureQuill.formatText(startIndex, textLength, {
          //     //   // unbolds 'hello' and set its color to blue
          //     //   link: "https://www.navertest.com",
          //     //   // insert: "changeText",
          //     // });
          //     lectureQuill.formatText(startIndex, textLength, {
          //       link: "https://www.nsssavertest.com",
          //       insert: "changeText",
          //     });
          //     lectureQuill.deleteText(startIndex, textLength);
          //     lectureQuill.insertText(startIndex, "changeText", {
          //       link: "https://www.nsssavertest.com",
          //     });
          //
          //     // note 지우지 말것
          //     // let url = lectureQuill.getContents(startIndex, textLength).ops[0]
          //     //   .attributes.link;
          //     // let rect = lectureQuill.getBounds(startIndex, textLength);
          //
          //     lectureQuill.getContents();
          //   } else {
          //     state.isCustomInput = false;
          //     let selection = window.getSelection();
          //     let startOffSet = selection.getRangeAt(0).startOffset;
          //     let startIndex = currentIndex - startOffSet;
          //     let textLength =
          //       selection.getRangeAt(0).startContainer.textContent.length;
          //     // let lastIndex =
          //     //   startIndex +
          //     //   selection.getRangeAt(0).startContainer.textContent.length;
          //     // console.log(
          //     //   selection.getRangeAt(0).startContainer.textContent.length
          //     // );
          //     console.log("startIndex", startIndex);
          //     console.log("textLength", textLength);
          //     // console.log(
          //     //   lectureQuill.getContents(startIndex, textLength).ops[0]
          //     //     .attributes.link
          //     // );
          //     // lectureQuill.formatLine(
          //     //   startIndex,
          //     //   textLength,
          //     //   "link",
          //     //   "testRomano"
          //     // );
          //
          //     console.log(
          //       lectureQuill.getContents(startIndex, textLength).ops[0]
          //     );
          //     const contents = lectureQuill.getContents();
          //     // contents.ops[startIndex].insert = "hihihihih";
          //     console.log(contents);
          //     // contents.ops[startIndex].insert = newText;
          //     console.log(contents.ops);
          //     console.log(contents.ops[startIndex]);
          //     // console.log(contents.ops[startIndex].insert);
          //     console.log("end");
          //     // contents.ops[startIndex].insert = "hihihihih";
          //     // contents.ops[startIndex].insert
          //     // lectureQuill.updateContents(contents);
          //   }
          //   // console.log(getFormat.hasOwnProperty("link"));
          //   // console.log(getFormat.prototype.hasOwnProperty.call("link"));
          //   // let isLinkTag = getFormat;
          // } else {
          //   let text = lectureQuill.getText(range.index, range.length);
          //   console.log("User has highlighted", text);
          //   console.log(lectureQuill.getContents());
          // }
        } else {
          console.log("Cursor not in the editor");
        }
      });
    });

    onBeforeUnmount(() => {
      lectureQuill.off("text-change", update);
      // note 비디오 기능
      // lectureQuill.off("selection-change", setLastSelection);
    });
    // note 비디오 기능
    //     const setLastSelection = (range, oldRange) => {
    //       if (range) {
    //         state.lastSelection = range.index;
    //         lectureEditor.value.classList.remove("none-cursor-state");
    //         state.activeEditor = true;
    //       } else {
    //         state.lastSelection = oldRange.index;
    //         lectureEditor.value.classList.add("none-cursor-state");
    //         state.activeEditor = false;
    //       }
    //     };

    const htmlToDelta = (htmlString) => {
      const div = document.createElement("div");
      div.setAttribute("id", "htmlToDelta");
      div.innerHTML = `<div id="quillEditor" style="display:none">${htmlString}</div>`;
      document.body.appendChild(div);
      const quill = new Quill("#quillEditor", {
        theme: "snow",
      });
      const delta = quill.getContents();
      document.getElementById("htmlToDelta").remove();
      return delta;
    };

    const update = (delta) => {
      if (delta) {
        if (lectureQuill.getLength() > 1) {
          state.value = lectureQuill.root.innerHTML;
        } else {
          state.value = "";
        }

        emit("updateValue", state.value);
      }
    };

    const actions = {
      updateVideoUrl: (e) => {
        // if (state.videoEmbedError) {
        //   state.videoEmbedError = "";
        // }
        console.log(state.videoUrl);
        state.videoUrl = e.target.value;
        console.log(state.videoUrl);
      },
      openLink: () => {
        // lectureQuill;
        console.log("openLink");
        let range = lectureQuill.getSelection();
        console.log(range);

        if (range == null) return;
        let preview = lectureQuill.getText(range);
        console.log(preview);
        state.isCustomInput = true;
        let rect = lectureQuill.getBounds(range.index, range.length);
        let contents = lectureQuill.getContents(range.index, range.length);
        // let link = contents[0].link;
        let connectedLink = contents.ops[0]?.["attributes"]?.link;
        // console.log(link.length);
        lectureEditor.value.append(customInput.value);
        customInput.value.style.top = rect.top + rect.height + "px";
        customInput.value.style.left = rect.left + "px";
        if (connectedLink) {
          state.url = connectedLink;
          lectureQuill.format("CustomLink", state.url);
        } else {
          state.url = "";
        }
      },
      editLink: (range, rect, url) => {
        state.isCustomInput = true;
        lectureEditor.value.append(customInput.value);
        customInput.value.style.top = rect.top + rect.height + "px";
        customInput.value.style.left = rect.left + "px";
      },
      createLink: () => {
        lectureQuill.format("link", state.url);
        state.isCustomInput = false;
        state.url = "";
      },
    };

    return { state, lectureEditor, actions, customInput, icons };
  },
});
</script>

<style scoped>
.open-hour-editor .ql-toolbar.ql-snow {
  gap: 8px;
}
.open-hour-editor .ql-toolbar button {
  width: 16px;
  height: 16px;
  padding: 0;
}

.open-hour-editor .ql-toolbar button.ql-active {
  background-color: #dadee6;
}

.custom-input {
  display: none;
  position: absolute;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
  /*background-color: snow;*/
  /*box-shadow: #818287;*/
  /*position: absolute;*/
  transform: translateY(10px);
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #79758f;
  padding: 5px 12px;
  white-space: nowrap;
}

.custom-input.isActive {
  display: block;
}
</style>
